import React from "react";
import styles from "./CrossForModal.module.scss";
import cl from "classnames";
import cross from "../../assets/img/png/cross.png";

export const CrossForModal: React.FC<any> = ({ className, ...props }) => {
	return (
		<div className={cl(styles.cross, className)} {...props}>
			<img src={cross} alt={""} />
		</div>
	);
};
