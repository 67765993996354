import { useEffect, useState } from "react";
import styles from "./TasksTab.module.scss";
import { toast } from "react-toastify";
import cl from "classnames";
import {
	fetchAddTask,
	fetchBroadcast,
	fetchGetTasks,
	fetchUpdateTask,
} from "../../api/admin";
import {
	IDescription,
	ISocialNetworksType,
	ITask,
	ITaskType,
} from "../../types/admin";
import { EditTask } from "../EditTask/EditTask";
import { modal } from "../../../common/ui/Modal/ModalConnector";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { parseISOString } from "../../utils/parseISOtoString";
import { differenceBetweenDates } from "../../utils/formatDate";
import { checkLink } from "../../utils/checkLink";

const TasksTab = () => {
	const [secretPhrase, setSecretPhrase] = useState<string>("");
	const [name, setName] = useState<string>("example");
	const [description, setDescription] = useState<IDescription>({
		social_network_type: ISocialNetworksType.custom,
		link: "example",
		type: ITaskType.custom,
		secretPhrase: secretPhrase.length > 0,
	});
	const [reward, setReward] = useState<string>("0");
	const [active, setActive] = useState<boolean>(true);
	const [password, setPassword] = useState<string>("");
	const [start, setStart] = useState<Date | null | undefined>(new Date());
	const [end, setEnd] = useState<Date | null | undefined>(new Date());
	const [duration, setDuration] = useState<string>("");
	const [isOpenSocialNetworksType, setIsOpenSocialNetworksType] =
		useState<boolean>(false);
	const [currentSocialNetworksType, setCurrentSocialNetworksType] =
		useState<ISocialNetworksType>(ISocialNetworksType.custom);
	const [isOpenTaskType, setIsOpenTaskType] = useState<boolean>(false);
	const [currentTaskType, setCurrentTaskType] = useState<ITaskType>(
		ITaskType.custom
	);
	const [link, setLink] = useState<string>("example");
	const [tasks, setTasks] = useState<ITask[]>([]);
	const [isShownActive, setIsShownActive] = useState<boolean>(true);
	const [isShownAll, setIsShownAll] = useState<boolean>(true);
	const [messageEn, setMessageEn] = useState<string>("");
	const [messageRu, setMessageRu] = useState<string>("");
	const [isSendMessage, setIsSendMessage] = useState<boolean>(false);

	useEffect(() => {
		setDescription({
			social_network_type: description.social_network_type,
			link: description.link,
			type: description.type,
			secretPhrase: secretPhrase.length > 0,
		});
	}, [secretPhrase]);

	const handleAddTask = async (
		name: string,
		description: IDescription,
		reward: string,
		active: boolean,
		start: string | null,
		end: string | null,
		password: string,
		secretPhrase: string,
		messageEn: string,
		messageRu: string
	) => {
		console.log("hello", description.secretPhrase, secretPhrase.length > 0);
		if (!checkLink(description.link, description.social_network_type, tasks)) {
			toast.error("Wrong link!");
			return;
		}
		try {
			const res = await fetchAddTask(
				name,
				description,
				reward,
				active,
				start,
				end,
				password,
				secretPhrase
			);
			if (res.data) {
				toast.success("Success!");
				await getTasks();
				if (isSendMessage) {
					await sendMessage(messageEn, messageRu, password);
				}
			} else {
				toast.error("Wrong password!");
			}
		} catch (e) {
			toast.error(JSON.stringify(e));
			console.log(e);
		}
	};

	const getTasks = async () => {
		try {
			const res = await fetchGetTasks();
			if (res.data.tasks) {
				let tasks: ITask[] = [];
				res.data.tasks.map((item: ITask, _index: number) => {
					try {
						let task: ITask = {
							name: item.name,
							description: JSON.parse(String(item.description)),
							reward: item.reward,
							active: item.active,
							start: item.start,
							end: item.end,
							id: item.id,
						};
						tasks.push(task);
					} catch (e) {
						console.log(e, item);
					}
					console.log("task", item);
				});
				console.log("tasks", tasks);
				setTasks(tasks);
			}
		} catch (e) {
			console.log(e);
		}
	};

	const sendMessage = async (
		messageEn: string,
		messageRu: string,
		password: string
	) => {
		try {
			const res = await fetchBroadcast(
				{ en: messageEn, ru: messageRu },
				password
			);
			if (res.data) {
				toast.success("Message sent!");
			}
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		getTasks();
	}, []);

	useEffect(() => {
		if (description.type === ITaskType.custom && start && end) {
			let date = differenceBetweenDates(start, end);
			if (date.days > 0) {
				setDuration(`${date.days} days`);
			}
		}
	}, [start, end]);

	useEffect(() => {
		let resCurrentSocialNetworksType = "";
		if (currentSocialNetworksType === ISocialNetworksType.discord) {
			resCurrentSocialNetworksType = "Discord";
		} else if (currentSocialNetworksType === ISocialNetworksType.instagram) {
			resCurrentSocialNetworksType = "Instagram";
		} else if (currentSocialNetworksType === ISocialNetworksType.tiktok) {
			resCurrentSocialNetworksType = "TikTok";
		} else if (currentSocialNetworksType === ISocialNetworksType.twitter) {
			resCurrentSocialNetworksType = "Twitter";
		} else if (currentSocialNetworksType === ISocialNetworksType.youtube) {
			resCurrentSocialNetworksType = "YouTube";
		} else if (currentSocialNetworksType === ISocialNetworksType.telegram) {
			resCurrentSocialNetworksType = "Telegram";
		} else if (currentSocialNetworksType === ISocialNetworksType.custom) {
			resCurrentSocialNetworksType = "Custom";
		}
		setMessageEn(`✔️ New task is live: ${resCurrentSocialNetworksType}!`);
		setMessageRu(`✔️ Новое задание доступно: ${resCurrentSocialNetworksType}!`);
	}, [currentSocialNetworksType]);

	const arrow = (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M17.707 10.707L12 16.414L6.29303 10.707L7.70703 9.293L12 13.586L16.293 9.293L17.707 10.707Z"
				fill="white"
			/>
		</svg>
	);

	return (
		<div className={styles.content}>
			<div className={styles.block}>
				<div className={styles.areas}>
					<div>
						<div className={styles.title}>name</div>
						<input
							value={name}
							onChange={(ev) => setName(ev.target.value)}
							className={styles.input}
						/>
					</div>
					<div>
						<div className={styles.title}>type of social network</div>
						<div className={styles.select}>
							<div
								className={styles.selectBc}
								onClick={() => {
									setIsOpenTaskType(false);
									setIsOpenSocialNetworksType(!isOpenSocialNetworksType);
								}}
							>
								<div className={styles.selectBcValue}>
									{currentSocialNetworksType}
								</div>
								<div
									className={cl(styles.selectBcArrow, {
										[styles.active]: isOpenSocialNetworksType,
									})}
								>
									{arrow}
								</div>
							</div>
							{isOpenSocialNetworksType && (
								<div className={styles.optionsSelectBc}>
									{Object.values(ISocialNetworksType).map((option) => {
										return (
											<div
												className={styles.optionSelectBc}
												onClick={() => {
													setCurrentSocialNetworksType(option);
													setIsOpenSocialNetworksType(
														!isOpenSocialNetworksType
													);
													setDescription({
														social_network_type: option,
														link: description ? description.link : "",
														type: description
															? description.type
															: ITaskType.custom,
														secretPhrase: secretPhrase.length > 0,
													});
												}}
											>
												<div>{option}</div>
											</div>
										);
									})}
								</div>
							)}
						</div>
					</div>
					<div>
						<div className={styles.title}>link</div>
						<input
							value={link}
							onChange={(ev) => {
								setDescription({
									social_network_type: description
										? description.social_network_type
										: ISocialNetworksType.custom,
									link: ev.target.value,
									type: description ? description.type : ITaskType.custom,
									secretPhrase: secretPhrase.length > 0,
								});
								setLink(ev.target.value);
							}}
							className={styles.input}
						/>
					</div>
					<div>
						<div className={styles.title}>task type</div>
						<div className={styles.select}>
							<div
								className={styles.selectBc}
								onClick={() => {
									setIsOpenSocialNetworksType(false);
									setIsOpenTaskType(!isOpenTaskType);
								}}
							>
								<div className={styles.selectBcValue}>{currentTaskType}</div>
								<div
									className={cl(styles.selectBcArrow, {
										[styles.active]: isOpenTaskType,
									})}
								>
									{arrow}
								</div>
							</div>
							{isOpenTaskType && (
								<div className={styles.optionsSelectBc}>
									{Object.values(ITaskType).map((option) => {
										return (
											<div
												className={styles.optionSelectBc}
												onClick={() => {
													setCurrentTaskType(option);
													setIsOpenTaskType(!isOpenTaskType);
													setDescription({
														social_network_type: description
															? description.social_network_type
															: ISocialNetworksType.custom,
														link: description ? description.link : "",
														type: option,
														secretPhrase: secretPhrase.length > 0,
													});
													if (option === ITaskType.general) {
														setStart(new Date());
														let nextYear = new Date();
														nextYear.setFullYear(nextYear.getFullYear() + 1);
														setEnd(nextYear);
														setDuration("1 year");
													}
													if (option === ITaskType.daily) {
														setStart(new Date());
														let tomorrow = new Date();
														tomorrow.setDate(tomorrow.getDate() + 1);
														setEnd(tomorrow);
														setDuration("1 day");
													}
												}}
											>
												<div>{option}</div>
											</div>
										);
									})}
								</div>
							)}
						</div>
					</div>
					<div className={styles.disabled}>
						<div className={styles.title}>duration</div>
						<input value={duration} className={styles.input} />
					</div>
					<div>
						<div className={styles.title}>reward</div>
						<input
							value={reward}
							onChange={(ev) => setReward(ev.target.value)}
							className={styles.input}
						/>
					</div>
					<div style={{ display: "flex", gap: "14px" }}>
						<div>
							<div className={styles.title}>active</div>
							<input
								className={styles.input}
								value={active as unknown as number}
								onChange={(e) => setActive(!active)}
								type="checkbox"
								checked={active ? true : false}
							/>
						</div>
						<div>
							<div className={styles.title}>send message?</div>
							<input
								className={styles.input}
								value={isSendMessage as unknown as number}
								onChange={(e) => setIsSendMessage(!isSendMessage)}
								type="checkbox"
								checked={isSendMessage ? true : false}
							/>
						</div>
					</div>
					<div
						className={cl({
							[styles.disabled]:
								currentTaskType === ITaskType.daily ||
								currentTaskType === ITaskType.general,
						})}
					>
						<div className={styles.title}>start</div>
						<DatePicker
							selected={start}
							onChange={(date) => setStart(date)}
							className={styles.input}
						/>
					</div>
					<div
						className={cl({
							[styles.disabled]:
								currentTaskType === ITaskType.daily ||
								currentTaskType === ITaskType.general,
						})}
					>
						<div className={styles.title}>end</div>
						<DatePicker
							selected={end}
							onChange={(date) => setEnd(date)}
							className={styles.input}
						/>
					</div>
					<div>
						<div className={styles.title}>password</div>
						<input
							value={password}
							onChange={(ev) => setPassword(ev.target.value)}
							className={styles.input}
						/>
					</div>
					<div>
						<div className={styles.title}>secret phrase</div>
						<input
							value={secretPhrase}
							onChange={(ev) => {
								setSecretPhrase(ev.target.value.toLocaleLowerCase());
							}}
							className={styles.input}
						/>
					</div>
					<div>
						<div className={styles.title}>message en</div>
						<textarea
							value={messageEn}
							onChange={(ev) => {
								setMessageEn(ev.target.value);
							}}
							className={cl(styles.input, styles.message)}
						/>
					</div>
					<div>
						<div className={styles.title}>message ru</div>
						<textarea
							value={messageRu}
							onChange={(ev) => {
								setMessageRu(ev.target.value);
							}}
							className={cl(styles.input, styles.message)}
						/>
					</div>
				</div>
				<div className={styles.bottom}>
					<button
						onClick={() =>
							handleAddTask(
								name,
								description,
								reward,
								Boolean(active),
								String(start?.toISOString()),
								String(end?.toISOString()),
								password,
								secretPhrase,
								messageEn,
								messageRu
							)
						}
						className={styles.btn}
					>
						add task
					</button>
					<div>
						<div className={styles.title}>show active</div>
						<input
							value={isShownActive as unknown as number}
							onChange={(e) => {
								setIsShownAll(false);
								setIsShownActive(!isShownActive);
							}}
							type="checkbox"
							checked={isShownActive ? true : false}
						/>
					</div>
					<div>
						<div className={styles.title}>show all</div>
						<input
							value={isShownAll as unknown as number}
							onChange={(e) => setIsShownAll(!isShownAll)}
							type="checkbox"
							checked={isShownAll ? true : false}
						/>
					</div>
				</div>
			</div>
			{tasks.length > 0 && (
				<table className={styles.table}>
					<thead>
						<tr>
							<td></td>
							<td>id</td>
							<td>name</td>
							<td>social_network_type</td>
							<td>link</td>
							<td>type</td>
							<td>reward</td>
							<td>active</td>
							<td>start</td>
							<td>end</td>
						</tr>
					</thead>
					<tbody>
						{tasks
							.sort(function (a, b) {
								return a.id - b.id;
							})
							.filter((i) => (!isShownAll ? i.active === isShownActive : {}))
							.map((item, index) => {
								return (
									<tr>
										<td>
											<button
												onClick={() =>
													modal.open(
														<EditTask
															task={{
																id: item.id,
																name: item.name,
																description: item.description,
																reward: item.reward,
																active: item.active,
																start: item.start,
																end: item.end,
															}}
															getTasks={getTasks}
															tasks={tasks}
														/>
													)
												}
												className={styles.btn}
											>
												update task
											</button>
										</td>
										<td>{item.id}</td>
										<td>{item.name}</td>
										<td>{item.description.social_network_type}</td>
										<td
											style={{ cursor: "pointer" }}
											onClick={() =>
												window.open(`${item.description.link}`, "_blank")
											}
										>
											{item.description.link}
										</td>
										<td>{item.description.type}</td>
										<td>{item.reward}</td>
										<td>
											<input
												className={styles.input}
												style={{ height: "30px" }}
												value={item.active as unknown as number}
												type="checkbox"
												checked={item.active ? true : false}
											/>
										</td>
										<td>{String(parseISOString(item.start))}</td>
										<td>{String(parseISOString(item.end))}</td>
									</tr>
								);
							})}
					</tbody>
				</table>
			)}
		</div>
	);
};

export default TasksTab;
