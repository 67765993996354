import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { CustomBrowserRouter } from "./app/routes/CustomBrowserRouter";
import { createBrowserHistory } from "history";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";
import { ModalConnector } from "./modules/common/ui/Modal/ModalConnector";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

export const history = createBrowserHistory();

root.render(
	<CustomBrowserRouter history={history}>
		<ToastContainer position="top-center" />
		<App />
		<ModalConnector />
	</CustomBrowserRouter>
);
