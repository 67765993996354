export interface IDescription {
	social_network_type: ISocialNetworksType;
	link: string;
	type: ITaskType;
	secretPhrase: boolean;
}

export enum ITaskType {
	general = "general",
	daily = "daily",
	custom = "custom",
}

export enum ISocialNetworksType {
	twitter = "twitter",
	instagram = "instagram",
	youtube = "youtube",
	tiktok = "tiktok",
	discord = "discord",
	telegram = "telegram",
	custom = "custom",
}

export interface ITask {
	name: string;
	description: IDescription;
	reward: string;
	active: boolean;
	start: string | null;
	end: string | null;
	id: number;
}

export interface IPromocode {
	value: string;
	reward: string;
	active: boolean;
	start: string | null;
	end: string | null;
	id: number;
}