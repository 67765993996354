import { useState } from "react";
import { AppRoutes } from "./app/routes/AppRoutes";
import { observer } from "mobx-react";
import { AuthorizeModal } from "./modules/admin/ui/AuthorizeModal/AuthorizeModal";

function App() {
	const [authorize, setAuthorize] = useState(false);

	return (
		<div>
			{/* {authorize ? ( */}
				<AppRoutes />
			{/* // ) : (
			// 	<AuthorizeModal setAuthorize={() => setAuthorize(true)} />
			// )} */}
		</div>
	);
}

export default observer(App);
