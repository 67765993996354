export const formatDate = (dateElement: Date, format?: string) => {
	let date;
	if (format === "fullDate") {
		date = dateElement;
	} else {
		date = new Date(dateElement);
	}
	const monthNames = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];
	let dd = date.getDate().toString();
	let mm = date.getMonth();
	let yyyy = date.getFullYear().toString();
	let min = date.getMinutes().toString();
	let hours = date.getHours().toString();
	if (Number(dd) < 10) {
		dd = "0" + dd;
	}
	if (Number(min) < 10) {
		min = "0" + min;
	}
	if (Number(hours) < 10) {
		hours = "0" + hours;
	}
	switch (format) {
		case "fullDate":
			return monthNames[mm] + " " + dd + ", " + yyyy + " " + hours + ":" + min;
		case "anotherFormat":
			if (Number(hours) >= 12) {
				hours = String(Number(hours) - 12);
				return hours + ":" + min + " PM";
			}
			return hours + ":" + min + " AM";
		default:
			return monthNames[mm] + " " + dd + ", " + yyyy;
	}
};

export const differenceBetweenDates = (startTime: Date, endTime: Date) => {
	let delta = Math.floor((endTime.getTime() - startTime.getTime()) / 1000);
	let days = Math.floor(delta / 86400);
	delta -= days * 86400;
	let hours = Math.floor(delta / 3600) % 24;
	delta -= hours * 3600;
	let minutes = Math.floor(delta / 60) % 60;
	delta -= minutes * 60;
	let seconds = delta % 60;
	return {
		days: days,
		hours: hours,
		minutes: minutes,
		seconds: seconds,
	};
};
